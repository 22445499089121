.topbar {
  position: relative;
  height: 55px;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  overflow: hidden; /* Prevent horizontal scrollbars */
}

.ticker-title {
  display: inline-flex;
  white-space: nowrap;
  font-size: 16px;
  animation: title 40s infinite linear;
}

.ticker-title p {
  display: inline-block;
  margin-right: 18px; /* Space between messages */
  font-weight: 500;
  color: rgb(45, 43, 40);
}

.ticker-title:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.icon {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  fill: none;
  stroke: #0a0a0a; /* White color for icons */
}

/* Individual icon styles */
.double-quote-left-icon,
.double-quote-right-icon {
  stroke: #0a0a0a; /* White for quote icons */
}

.discount-icon {
  stroke: #ff5722; /* Orange for discount icon */
}

.arrow-icon {
  stroke: #4caf50; /* Green for arrow icon */
}

/* Marquee animation */
@keyframes title {
  0% {
    transform: translateX(0);
}
100% {
  transform: translateX(-1920px);
}
}
