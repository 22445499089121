.custom_container {
  padding: 0 50px;
}

.searchSection {
  background: #f4f4f7;
}

.search-container {
  /* background: #eef1fb; */
  padding: 35px 40px;
  background: #f4f4f7;
  min-height: 450px;
}

.search-container h1 {
  font-weight: 900;
  font-size: 50px;
  line-height: 1.2;
  margin-top: -12px;
  color: rgb(45, 43, 40);
  margin-bottom: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
  /* font-family: "WoltHeading-Omnes", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif; */
}


.search-container h2 {
  color: rgb(45, 43, 40);
  font-weight: 500;
  font-size: 18px;
  line-height: 29.2px;
  letter-spacing: 1px;
}

.search-container h3 {
  color: rgb(45, 43, 40);
  font-weight: 500;
  font-size: 16px;
  line-height: 29.2px;
  letter-spacing: 1px;
}

.home_search {
  padding-top: 20px;
  position: relative;
}

.home_search .ant-input-wrapper {
  height: 55px;
  border: 2px solid #ddd;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
}

.home_search .ant-input-wrapper:hover {
  border: 2px solid #009de0;
}

.home_search .ant-input-outlined {
  border: none;
}

.home_search .ant-input-wrapper input {
  height: 51px !important;
  padding-left: 16px;
  padding-right: 10px;
}

.home_search .ant-input-wrapper input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.home_search .ant-input-search-large .ant-input-search-button {
  height: 51px !important;
  border: none;
}

.home_search .ant-select {
  height: 53px;
}

.home_search .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  padding-left: 20px;
  padding-right: 40px;
}

.home_search svg {
  right: 16px;
  position: absolute;
  transform: translateY(-49%);
  top: 50%;
  z-index: 99;
  font-size: 21px;
}

.delivery-info input {
  height: 55px;
  border: 2px solid #ddd !important;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  padding-left: 16px;
}

.searchText {
  position: relative;
  font-size: 1.6rem;
  color: rgb(51, 51, 51);
  font-weight: 600;
  z-index: 9;
  padding: 10px;
  width: 100%;
  line-height: normal;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 0px;
  margin: 0px;
  transition: border 0.15s ease-in;
  appearance: none;
}

.searchText:hover {
  border: 3px solid #009de0;
}

.radio-buttons {
  margin-bottom: 20px;
  /* Space between the radio buttons and input */
}

.radio-buttons .ant-radio-group label {
  flex: 1;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  background: #e3e2db;
  border-radius: 100px;
  font-size: 16px;
  text-align: center;
}

.radio-buttons .ant-radio-group label:hover {
  color: #fff !important;
}



.radio-buttons .ant-radio-group {
  display: flex;
  /* Flexbox for alignment */
  background: #e3e2db;
  border-radius: 100px;
}

.radio-buttons .ant-radio-button-wrapper {
  border: none;
}

.radio-buttons .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}


.radio-buttons .ant-radio-button {
  background-color: #f0f0f0;
  /* Background color for buttons */
  color: #3b3d2f;
  /* Text color */
  padding: 10px 20px;
  /* Padding for buttons */
  border-radius: 50px;
  /* Rounded corners */
}

.radio-buttons .ant-radio-button-checked {
  background-color: #03343b;
}


.ant-modal-footer .ant-btn-color-primary {
  background: #009de0;
  height: 40px;
}

.ant-modal-footer .ant-btn-color-default {
  background: #ebf7fd;
  border: none;
  height: 40px;
}

.ant-modal-footer .ant-btn-color-default:hover {
  background: #ebf7fd !important;
}

.radio-buttons .ant-radio-group label.ant-radio-button-wrapper-checked {
  color: #fff;
}

.radio-buttons .ant-radio-group label.ant-radio-button-wrapper-checked:hover {
  color: #fff !important;
}

.radio-buttons .ant-radio-group label:hover {
  color: #03343b !important;
}

.SliderBanner img {
  height: 540px;
  width: 100%;
  object-fit: cover;
}

.radioSpan span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.directOrder_search {
  padding-top: 0px;
  position: relative;
}

.directOrder-button-container {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 20px;
}

.directOrder-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  text-align: center;
}

.directOrder-btn svg {
  margin-bottom: 8px;
}

.directOrder-btn-delivery {
  background-color: #428cff;
  color: #fff;
}

.directOrder-btn-delivery:hover {
  background-color: #0056b3;
  color: #fff;
}

.directOrder-btn-collection {
  background-color: #ff4d4f;
  color: #fff;
}

.directOrder-btn-collection:hover {
  background-color: #e10d1e;
  color: #fff;
}

.directOrder-btn-table {
  background-color: #4d4d4d;
  color: #fff;
}

.directOrder-btn-table:hover {
  background-color: #121111;
  color: #fff;
}



/* Responsive CSS Start Here */

/* Extra small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .SliderBanner {
    display: none !important;
  }

  .aboutLeftContent {
    padding: 50px 40px 60px 40px;
  }

  .foodCategoriesSection .ant-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }

  .categoryPriceInfo {
    gap: 10px;
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}