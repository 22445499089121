.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full page height */
    background-color: #f5f5f5; /* Optional: Background color */
  }
  
  .loading-message {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #555; /* Optional: Text color */
  }
  