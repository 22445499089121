/* src/assets/css/styles.css */
body {
  margin: 0;
  /* font-family: "WoltHeading-Omnes", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif; */
}
.gray {
  background: #f4f4f7;
}

/* Style for the left and right arrows */
.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  background-color: #07D607; /* Blue background */
  display: flex;
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  border-radius: 50%; /* Circular background */
  opacity: 0.8;
  height: 40px; /* Increased size */
  width: 40px; /* Increased size */
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover {
  background-color: #2574f5; /* Darker blue on hover */
  opacity: 1;
}

/* Style for the ::after pseudo-element (arrow icon) */
.slick-arrow.slick-next::after,
.slick-arrow.slick-prev::after {
  content: ''; /* Ensure the content is there */
  display: block; /* Make sure it's a block-level element */
  width: 14px; /* Adjust the arrow icon size */
  height: 14px; /* Adjust the arrow icon size */
  position: absolute; /* Position it within the circle */
  top: 30%; /* Position at the vertical center */
  left: 30%; /* Position at the horizontal center */
 
}

/* Hover and focus states for accessibility and better interactivity */
.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:focus,
.slick-arrow.slick-prev:focus {
  background-color: #2574f5; /* Darker background on hover/focus */
  opacity: 1; /* Full opacity on hover */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 5px 2px rgba(0, 123, 255, 0.5); /* Blue focus outline */
}
