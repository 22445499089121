.hometimeSection {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.sectionHeading {
    margin-bottom: 20px;
    color: rgb(45, 43, 40);
    font-weight: 900;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
}

.hoursList {
    list-style-type: none;
    padding: 0;
}

.hourItem {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    transition: all 0.3s ease;
}

.hourItem.highlighted {
    background-color: #fffae6;
    /* Highlight background color */
    font-weight: bold;
}

.hourItem.dimmed {
    opacity: 0.9;
    /* Dimmed opacity for other rows */
}

.hourItem.closed {
    color: red;
    font-style: italic;
}

.day {
    font-weight: bold;
}

.time {
    color: gray;
}

.hourItem {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
}

.hourItem.closed .time {
    color: red;
}

.hometimeList {
    list-style: none;
    padding: 0;
}

.hometimeItem {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.hometimeItem i {
    margin-right: 10px;
    color: #009de0;
}

.map iframe {
    border: none;
    margin-top: 10px;
}

/* General styling for the special message */
.special-message {
    /* margin: 20px auto; */
    padding: 15px 20px;
    background-color: #fffae6;
    border: 2px solid #ffcc00;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: blink-border 1.5s infinite;
}
  
  /* Paragraph styling */
  .special-message p {
    font-size: 15px;
    font-weight: bold;
    margin: 1px;
    animation: blink-text 1s infinite;
}
  
  /* Blinking border effect */
  @keyframes blink-border {
    0% {
      border-color: #ffcc00;
    }
    50% {
      border-color: #ff6f61; /* Slightly darker highlight */
    }
    100% {
      border-color: #ffcc00;
    }
  }
  
  /* Blinking text effect */
  @keyframes blink-text {
    0%, 100% {
      color: #d9534f; /* Red text */
    }
    50% {
      color: #f0ad4e; /* Orange text */
    }
  }
  