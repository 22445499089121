.breadCrumbSection {
    padding: 10px 0;
}

.checkoutSection {
    background: #fbfbfb;
    padding-top: 15px;
    padding-bottom: 50px;
}

.paymentMethodGroup {
    margin-top: 15px;
    margin-bottom: 15px;
}

.breadCrumbNav {
    font-weight: 600;
    font-size: 19px;
    line-height: 116.5%;
    color: #3b3d2f;
}

.breadCrumbNav .anticon {
    font-size: 19px;
}

.breadCrumbNav a:hover {
    background-color: transparent !important;
}

.commonSection {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 0 0.25rem 0 rgba(0, 0, 0, 0.1), 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.12);
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.orderCheckoutSection {
    margin-top: 30px;
}

.orderWelcomeSection .customHeading,
.deliveryAddressSection .customHeading,
.paymentSection .customHeading {
    margin: 0;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;}

.customPText {
    margin-top: 5px;
    font-size: 15px;
    margin-bottom: 30px;
}



.welcomeWrap {}

.checkoutForm input {
    padding: 1.2rem !important;
    border: 1px solid #cccccc !important;
    border-radius: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px !important;
    height: 45px !important;
}

.checkoutForm .form-control {
    padding-left: 45px !important;
}

.checkoutButton {
    padding: 7px 30px;
    font-weight: 600;
    height: inherit;
    font-size: 17px;
}

.checkoutSectionMainWrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.checkoutLeftIcon {
    flex: 1 0 auto;
    padding: 0 10px;
}

.checkoutRightWrap {
    flex: 1 1 90%;
    padding: 0 10px;
    max-width: 90%;
}

.commonSection:after {
    content: "";
    position: absolute;
    left: 30px;
    bottom: -20px;
    border-left: 1px dashed rgb(192, 192, 201);
    height: 20px;
}

.commonSection:last-child::after {
    content: "";
    border: 0px;
}

.checkoutForm label {
    font-weight: 600;
}

.checkoutForm .formControl {
    padding-left: inherit;
}

.checkoutForm .ant-form-item-row {
    flex-direction: unset !important;
}

.checkoutForm .ant-form-horizontal .ant-form-item {
    margin-bottom: 0;
}

.ant-form-item-required {
    flex-direction: row-reverse;
}

.ant-form-item-label>label::after {
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}

.addAddressWrap {
    margin-top: 20px;
    cursor: pointer;
    background: rgb(248, 248, 255);
    border-radius: 6px;
    font-size: 20px;
    padding: 10px;
    border: 2px dashed #009de0;
    min-height: 50px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.addAddressWrap svg {
    margin-right: 10px;
}

.checkoutModal .customHeading {
    margin: 0;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;}

.checkoutModal input {
    padding: 1.2rem !important;
    border: 1px solid #cccccc !important;
    border-radius: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px !important;
    height: 45px !important;
}

.checkoutModal label {
    font-weight: 600;
}

.checkoutModal .ant-form-item {
    margin-bottom: 20px;
}

.customRadio .ant-radio-button-wrapper-checked {
    color: #fff;
    background: #009de0 !important;
    border-color: #009de0 !important;
}

.checkoutModal label svg {
    margin-right: 5px;
}

.w100 {
    width: 100%;
}

.checklistAddressList {
    margin-top: 20px;
}

.addressRadioList label {
    border: 1px #ddd solid;
    padding: 20px;
}

.addressWrap {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    align-items: baseline;
}

.addressIco {
    flex: 1 0 auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    font-size: 17px;
}

.addressDetails {
    flex: 1 1 90%;
    padding: 0 15px;
    max-width: 90%;
}

.addressDetails h5 {
    margin: 0;
}

.addressDetails p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.addressRadioList label .ant-radio {}

.addressRadioList {
    display: flex;
}

.addressDetails svg {
    margin-right: 5px;
}

.paymentHeading {
    background: #f3f3f3;
    padding: 10px;
    border-radius: 3px;
}

.paymentHeading svg {
    margin-right: 5px;
}

/* .cardImgHeading{text-transform: uppercase;} */

.cardGroup {
    display: flex;
    gap: 5px;
}

.cardGroup img {
    width: 26px;
    height: 26px;
}

.cardGroupWrap {
    display: flex;
    gap: 10px;
}

.cardImgHeading {
    font-weight: 600;
}

.mt0 {
    margin-top: 0;
    margin-bottom: 20px;
}


#pac-input {
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-top: 10px;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 360px;
    height: 35px;
  }
  
  #pac-input:focus {
    border-color: #4d90fe;
  }

  .specialCommentSection {
    margin-bottom: 20px; /* Adjust margin for spacing */
  }
  
  .driverTipSection {
    margin-bottom: 20px; /* Adjust margin for spacing */
  }
  
  .cardElement {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #f9f9f9;
  }
  
  .cardElement:focus {
    border-color: #007bff;
    background-color: #fff;
  }
  
  