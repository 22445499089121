
    /* Section Title */
  .sectionTitle {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .offercustomHeading {
    font-size: 18px;
    margin-top: 10px;
    color: #007bff;  /* Customize the color */
  }
  
  
  /* Card Styles */
  .offerCard,
  .couponCard {
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .offerCard:hover,
  .couponCard:hover {
    transform: translateY(-5px);
  }
  
  .offerImage {
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .offerTitle {
    font-weight: 600;
    font-size: 1.2rem;
  }
  
  .offerDescription,
  .couponDescription {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  .couponExpiry {
    font-size: 0.85rem;
    color: #999;
    margin-bottom: 15px;
  }
  
  /* Button and Tag Styles */
  .couponTag,
  .applyButton,
  .copyCodeButton {
    font-size: 0.95rem;
  }
  
  .applyButton,
  .copyCodeButton {
    border-radius: 5px;
  }
  .offerTags .ant-tag {
    margin-right: 8px; /* Space between tags horizontally */
    margin-bottom: 8px; /* Space between tags vertically */
  }
    