@font-face {
    font-family: "WoltHeading-Omnes";
    font-style: normal;
    font-weight: 700;
    src: url("../css/fonts/OmnesLatin-Bold.woff2") format("woff2"), url("../css/fonts/OmnesLatin-Bold.woff2") format("woff");
    unicode-range: u+0-10ffff;
}

.ContentBanner {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.ContentBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}