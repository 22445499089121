.cartWrap {
    background: #fff;
    margin: 16px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgb(232, 232, 232) 0px 2px 12px 0px;
}

.writeInstructionWrap {
    background: #fff;
    margin: 16px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgb(232, 232, 232) 0px 2px 12px 0px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkoutCartSection h3 {
    margin: 0;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;    position: sticky;
    top: 0px;
    z-index: 9;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    box-shadow: rgb(232, 232, 232) 0px 2px 12px 0px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.cartItemName svg {
    margin-right: 5px;
}

.cartItemName {
    font-weight: 500;
}

.itemPricedetails {
    font-weight: 500;
    margin-left: 10px;
}

.inputQty {
    width: 26px;
    background: #ebf7fd;
    border: 1px #009de0 solid;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 0;
    border-right: 0;
}

.inputQtyWrap .minusBtn {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 4px 10px;
    height: 27px;
    border-color: #009de0;
    background: #ebf7fd;
    color: #009de0;
    border-right: 0;
}

.inputQtyWrap .minusBtn:hover,
.inputQtyWrap .plusBtn:hover {
    background: #009de0 !important;
    color: #fff !important;
}

.inputQtyWrap .plusBtn {
    border-radius: 4px;
    border-top-left-radius: 0;
    padding: 4px 10px;
    height: 27px;
    border-bottom-left-radius: 0;
    border-color: #009de0;
    background: #ebf7fd;
    color: #009de0;
    border-left: 0;
}

.cartListItem .ant-list-item {
    justify-content: space-between;
    padding: 8px 0;
}

.applyCuponWrap {
    background: #fff;
    margin: 16px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgb(232, 232, 232) 0px 2px 12px 0px;
}

.applyCuponWrap input {
    height: 40px;
}

.applyCuponWrap button {
    height: 40px;
    gap: 3px;
}

.applyCuponWrap button svg {
    width: 25px;
}

.checkoutCartSection {
    padding: 0;
    background-color: #f6f6f6;
    padding-bottom: 5px;
}

.cartContainer {
    position: relative;
    /* height: calc(-300px + 100vh);
    overflow: hidden auto; */
    /* scrollbar-width: thin;
    scrollbar-color: rgb(0 157 224) rgb(0 157 224); */
}

.writeInstructionWrap input {
    display: flex;
    align-items: center;
    gap: 10px;
}

.billSummaryWrap {
    background: #fff;
    margin: 16px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgb(232, 232, 232) 0px 2px 12px 0px;
}

.billHead {
    margin-top: 0;
}

.billSummary {
    border-radius: 4px;
    border: 1px solid rgb(230, 230, 230);
    overflow: hidden;
    padding: 10px 0 0;
}

.billItemWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 10px;
}

.billItemTitle {
    font-weight: 500;
}

.billItemValue {
    color: #666;
}

.billTotalDiscountWrap {
    background-color: rgb(248, 248, 255);
    border-top: 1px solid rgb(238, 238, 238);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.billTotalDiscountTitle,
.billTotalDiscountValue {
    color: #199064;
    font-weight: 500;
}

.billPayWrap {
    margin: 16px;
}

.billPayWrap button {
    width: 100%;
}