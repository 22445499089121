/* Ensure the card takes up full height and width */
.popularcate-carousel .ant-card {
  position: relative;
  height: 100%;
  overflow: hidden; /* Hide any overflow content */
  border-radius: 8px; /* Optional: add rounded corners */
}
.popularcate-carousel .ant-card-body {
 padding: 1px;
}

.popularcate-products-card {
  margin: 0 6px; /* Adds space on both sides of each card */
  display: flex;
  flex-direction: column;
}

/* Make the image cover the entire card */
.popularcate-carousel .category-image {
  width: 100%;
  height: 450px;
  object-fit: cover; /* Ensure the image fills the space */
  border-radius: 8px;
  transition: opacity 0.3s ease;
}

/* Container for the card overlay */
.popularcate-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent black overlay */
  z-index: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

/* Count at the top */
.popularcate-count {
  font-size: 16px;
  color: white;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Content at the bottom */
.popularcate-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align content at the bottom */
  color: white;
}

.popularcate-content .info {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 30px;
}

.popularcate-content .info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.popularcate-content .info small {
  font-size: 14px;
  color: #f8f8f8;
}

.popular-categories-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
