.textCenter{text-align: center;}
.reviewSection{ padding: 3rem 0;}
.reviewSection .customHeading {
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 900;
    color: rgb(45, 43, 40);
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
  }
.customer-reviews-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .reviews-box-card {
    margin: 0 6px;
    display: flex;
    flex-direction: column;
  }
.reviewBoxWrap{background:#eef1fb; padding: 20px; border-radius: 15px;}
.reviewInfo{display: flex; justify-content: space-between;
     align-items: center; flex-wrap: wrap; width: 100%; margin-bottom: 12px;}
.reviewUserImg img{border-radius: 50%;
    width: 58px;
    height: 58px;
    border: 3px #009de0 solid;}
.reviewUserName{}
.reviewHeading{text-transform: uppercase; letter-spacing: 1px; margin:0 !important;}
.reviewText{font-size: 15px;}
.ratingStar{ padding-top: 5px;}

.ratingStar .ant-rate{font-size: 16px; color: #07D607;}
.reviewDate{display: block;
font-weight: 500;}