.aboutSection {
    background-color: #fff;
    background-repeat: no-repeat;
    background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #f8f8f8, #f8f8f8);
    background-position: 0px 0px, 0px 50%;
    background-size: 100% 50%, 100%, 50%;
    padding-bottom: 2.5rem;
    padding-top: 1rem;
}

.aboutSection .customHeading {
    margin-top: 5px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
   /*  font-family: "WoltHeading-Omnes", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif; */
}

.aboutInnerWrap {
    box-shadow: rgba(32, 33, 37, 0.06) 0px 0px 54px 0px;
}

.aboutLeftContent {
    display: flex;
    background: #fff;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 30px 60px 0px 20px;
    position: relative;
}

.aboutDetails {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 20px;
}

.aboutSection .subHeading {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
}

.aboutBanner {
    left: 0;
    margin: 0 auto;
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
}

.aboutBanner img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    object-fit: cover;
    overflow: hidden;
    box-shadow: -10px 0px 20px rgba(0, 0, 0, 0.2);
}

.aboutBtn {
    border: none;
    box-shadow: none;
    padding: 0;
    justify-content: flex-start;
}

.playBtn {
    align-items: center;
    background-color: #07D607;
    border-radius: 50%;
    display: flex;
    height: 3.75rem;
    justify-content: center;
    min-width: 3.75rem;
    transition: transform .15s ease-out;
    width: 3.75rem;
}

.playBtnSvg {
    color: #fff;
    height: 4rem;
    width: 4rem;
}

.watchLink {
    margin-inline-end: 0;
    margin-inline-start: 0.20rem;
    color: #07D607;
    font-size: 25px;
    margin-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
    font-weight: 700;
}