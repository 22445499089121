.service-products-card {
  margin: 0 6px;
  display: flex;
  flex-direction: column;
}

.ourservices-carousel-wrapper .service-card-wrapper {
  padding: 0 10px;
}

.ourservices-carousel-wrapper .service-card-wrapper .ant-card {
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.ourservices-carousel-wrapper .service-card-wrapper .ant-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.ourservices-carousel-wrapper .service-card-wrapper .ant-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.ourservices-carousel-wrapper .service-card-wrapper .service-title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #333;
}

.ourservices-carousel-wrapper .service-card-wrapper .service-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.ourservices-carousel-wrapper .service-card-wrapper .read-more-btn {
  background-color: #ff4d4f;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.ourservices-carousel-wrapper .service-card-wrapper .read-more-btn:hover {
  background-color: #d9363e;
  color: white;
}

/* Ensure the carousel container is correctly sized */
.ourservices-carousel-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
}
