.special-meals-section {
    
  }
  
  .special-meals-card {
    margin: 0 6px; /* Adds space on both sides of each card */
    display: flex;
    flex-direction: column;
  }
  
  .special-meals-section .special-meals-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .offer-card
  {
    padding: 0px 0px 6px 10px;
    display: flex;
    gap: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    visibility: visible;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
  }
  .offer-content {

  }
  .offer-card .offer-content h6 {
    color: #eb0029;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 63px;
}

.offer-card .offer-content h3 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.theme-btn {
    display: inline-block;
    position: relative;
    padding: 14px 28px 38px 28px;
    background-color: #eb0029;
    color: #fff;
    border: 1px solid #EB0029;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    z-index: 1;
    overflow: hidden;
    border: none;
    transition: all 0.3s ease-in-out;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.theme-btn.style4 {
    background-color: #eb0029;
    color: #fff;
    border: 1px solid #EB0029;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.offer-card .offer-thumb {
    position: relative;
}

.offer-card .offer-thumb .thumbImg {
    width: 276px;
    height: 260px;
    object-fit: contain;
}

@media (max-width: 1600px) {
    .offer-card .offer-thumb .thumbImg {
        width: 139px;
    }
}

.offer-card .offer-thumb .shape {
    position: absolute;
    top: 0;
    left: -30px;
}

.float-bob-x {
    -webkit-animation-name: float-bob-x;
    animation-name: float-bob-x;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}