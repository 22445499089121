body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
/*   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Add this CSS to your global stylesheet */
.ant-message {
  font-size: 16px; /* Increase font size */
  font-weight: bold; /* Make text bold */
  color: #4caf50; /* Optional: Change text color */
}

.ant-message-notice {
  background-color: #f0f9eb; /* Optional: Add custom background color */
  border-radius: 5px; /* Optional: Add rounded corners */
  padding: 10px; /* Optional: Add padding */
  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #009de0;
}
