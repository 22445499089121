.footerSection {
     text-align: center;
     background: #03343b;
     color: #fff;
}

.footerLeft {
     text-align: left;
     padding: 10px 0;
}

.footerMenu {
     background: transparent !important;
     color: #fff !important;
}

.footerMenu li {
     color: #fff !important;
}

.footerMenu li:first-child {
     padding-left: 0;
}

.ftLogo img {
     width: 25%;
     border-radius: 4px;
}

.copyrightFooter {
     border-top: 1px dotted rgb(82, 98, 116);
     padding-top: 20px;
     padding-bottom: 20px;
     font-size: 18px;
}

.footerHeading {
     color: #fff !important;
     text-align: left;
     text-transform: uppercase;
     margin-bottom: 18px;
}

.footerAppStore {
     text-align: left;
}

.downloadapp_subtitle {
     margin-top: -5px;
     margin-bottom: 9px;
     color: #fff !important;
     text-align: left;
     text-transform: uppercase;
     margin-bottom: 18px;
}

.appStoreBtn img {
     width: 8.6rem;
     height: 2.5rem;
     cursor: pointer;
}

.appStoreBtn+.appStoreBtn {
     margin-left: 1.5rem;
}


/* Desktop Footer */
.desktopFooter {
     display: block;
}

/* Mobile Footer */
.mobileFooter {
     display: none;
     background-color: #f0f0f0;
     position: fixed;
     bottom: 0;
     width: 100%;
     padding: 10px 0;
}

.mobileMenu {
     display: flex;
     justify-content: space-around;
     border: none;
}

/* Show the mobile footer and hide desktop footer on small screens */
@media (max-width: 768px) {
     .desktopFooter {
          display: none;
     }

     .mobileFooter {
          display: block;
     }
}

/* Scroll to top button styling */
.scroll-to-top {
     position: fixed;
     bottom: 20px; /* Distance from the bottom */
     right: 20px; /* Distance from the right */
     background-color: #428cff; /* Blue background */
     color: white;
     border-radius: 50%;
     padding: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     opacity: 0.8; /* Slight opacity */
     transition: opacity 0.3s ease, transform 0.3s ease;
     z-index: 1000; /* Ensure it's on top of other content */
   }
   
   .scroll-to-top:hover {
     background-color: #2574f5; /* Darker blue on hover */
     opacity: 1; /* Full opacity on hover */
     transform: scale(1.1); /* Slight scale effect */
   }
   
   .scroll-to-top svg {
     width: 24px;
     height: 24px;
     fill: white;
   }
   
   .scroll-to-top.hidden {
     display: none;
   }
   