.categories-container {
  display: flex;
  flex-direction: column;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.comparisonContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.comparisonTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.comparisonList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.comparisonItem {
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    border: 2px dotted #ffe0e0;
}

.platformName {
  font-weight: bold;
  font-size: 1rem;
  margin-right: 10px;
}

.price {
  font-size: 1rem;
  margin-right: 10px;
}

.discount {
  color: #ff000d;
  font-weight: 500;
  font-size: 0.9rem;
}

.saving {
  color: #28a745;
  font-weight: bold;
  margin-top: 5px;
}

.priceArrow {
  margin-left: 5px;
  fill: #ff000d;
}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.search-input:focus {
  border-color: #0070f3;
  box-shadow: 0 0 0 3px rgba(0, 112, 243, 0.1);
}

.search-icon {
  width: 24px;
  height: 24px;
  fill: #ccc;
  margin-left: 8px;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.category-link {
  text-decoration: none;
  padding: 8px 12px;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 18px;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category-link:hover {
  background-color: #0070f3;
  color: #fff;
}

.category-link:focus {
  outline: none;
  border-color: #0070f3;
}

.categories-container {

  background: white;
  z-index: 1000;
  border-bottom: 1px solid #e4e4e5;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 4px 12px 0px #0000001f, 0px 1px 2px 0px #0000001f, 0px 0px 1px 0px #0000001f;
  width: 100%;
  left: 0;
  right: 0;
  position: sticky; /* Sticky header */
  top: 0; /* Stick to the top */
  z-index: 9999; /* Ensure it stays on top */
}

.category-list-horizontal {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Ensure proper positioning of dropdown */
}

.ant-dropdown-menu-vertical
{
  margin-top: 27px !important;
}

.menuCateLeft {
  display: flex;
  gap: 10px;
  margin-left: 4%;
}


.menuCateRight a {
  cursor: pointer;
}

.menuCateRight a:hover {
  color: #009de0;
}

.category-dropdown-btn {
  width: 100%;
}

@media (max-width: 768px) {
  .category-list-horizontal {
    display: none;
  }
  .categories-container {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative; 
  }

  .categoryBtn
  {
    width: 100%;
    display: list-item;
    text-align: left;
    border-radius: 2px;
  }
  
}

