/* src/components/layouts/Header.css */
.custom_layout {
  height: 64px;
}

.custom-header {
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: white;
  border-bottom: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Add box shadow */
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  width: 100%;
}

.custom-header ul {
  width: 65%;
  display: flex;
  justify-content: flex-end;
}


.ant-menu-light.ant-menu-horizontal>.ant-menu-item::after {
  border-bottom: none !important;
}


.header-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 52px;
}

.header-container .logo {
  position: absolute;
  -webkit-animation: appear .5s;
  animation: appear .5s;
  min-width: 100px;
  margin-left: auto;
  bottom: 5.25px;
  height: 62px;
  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  height: 60px;
  margin-top: 2px;
}

.menu .ant-menu-item {
  font-weight: 500;
  color: #3b3d2f;
  font-size: 16px;
  padding-inline: 0 20px;
}

.menu .active_menu {
  font-weight: 700;
  color: #009de0;
}

.menu .active_menu:hover a {
  color: #009de0;
}

.menu .ant-menu-item:hover,
.menu .ant-menu-item:hover::after {
  border-bottom: none !important;
}

.menu .active_menu .ant-menu-title-content {
  position: relative;
}

.menu .active_menu a:after {
  content: "";
  width: 100%;
  border-bottom: 1px solid #009de0;
  height: 1px;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.menu .btn_sign .ant-menu-title-content {
  color: #009de0;
  /* border: 2px solid #3b3d2f; */
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 24px;
  border-radius: 4px;
  min-width: 108px;
  text-align: center;
  background: #ebf7fd;
}

.menu .btn_join .ant-menu-title-content {
  background: #07D607;
  color: #fff;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 24px;
  border-radius: 4px;
  min-width: 108px;
  text-align: center;
}

.cart-item {
  margin-left: auto;
  /* Push cart item to the end */
}

.cart-icon {
  font-size: 50px;
  position: relative;
  top: 7px;
}

.header-container .btn {
  margin-left: 10px;
  /* Space between buttons */
  background: transparent;
  /* Transparent background */
  color: #3b3d2f;
  /* Text color */
  border: 2px solid #3b3d2f;
  /* Button border */
  height: 35px;
  /* Set button height */
  font-weight: 400;
  /* Font weight */
  padding: 0 20px;
  /* Padding */
  text-decoration: none;
  /* No underline */
  display: flex;
  /* Align text in center */
  align-items: center;
  /* Center align vertically */
  justify-content: center;
  /* Center align horizontally */
  border-radius: 48px;
}

.header-container .btn:hover {
  background-color: #f0f0f0;
  /* Background color on hover */
}

/* Mobile Styles */
.menu-toggle {
  display: none;
  /* Hide toggle icon by default */
}

.authBg {
  background-image: url(../images/backgroundLoginNew.svg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 140px;
  min-height: 140px;
  display: flex !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.authModal .ant-modal-content {
  padding: 0;
}

.authModalBody {
  padding: 20px;
}

.authModalBody .modalHeading {
  margin: 0;
}

.authModalBody .modalSubHeading {
  font-size: 16px;
  margin-top: 5px;
  color: #9d9d9d;
}

.authModal .anticon-close svg {
  background: #000;
  color: #fff;
  padding: 7px;
  border-radius: 20px;
}

.w100 {
  width: 100%;
}

.ant-btn-primary {
  background: #009de0;
}

.customModalBtn {
  height: 45px;
  font-size: 18px;
}

.authFooter {
  color: rgb(102, 102, 102);
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  margin-top: 40px;
}

.authFooter span {
  color: rgb(73, 69, 190);
}

.phoneInputWrap input {
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px !important;
  height: 45px !important;
  padding-left: 45px !important;
}

.custom_input_field {
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px !important;
  height: 45px !important;
}

.custom_textarea_field {
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px !important;
  height: 100px !important;
}

.contactWrap {
  padding: 40px 0 0;
}

.contactSection {
  background: #fbfbfb;
  padding-bottom: 40px !important;
}

.customInnerHead {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
}

.contactForm {
  font-weight: 600;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
}

.contactDetails {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
}

.contactDetails .ant-space-gap-row-middle {
  row-gap: 11px;
}

.facebookIco {
  color: #fff;
  font-size: 24px;
  background: #4267b2;
  padding: 13px;
  border-radius: 12px;
}

.instagramIco {
  color: #fff;
  font-size: 24px;
  background: #C13584;
  padding: 13px;
  border-radius: 12px;
}

.youtubeIco {
  color: #fff;
  font-size: 24px;
  background: #FF0000;
  padding: 13px;
  border-radius: 12px;
}

.ant-drawer-right {
  z-index: 999999 !important;
}


.socialFollowWrap {
  margin-top: 20px;
}

.innerCommonWrap {
  padding: 40px 0;
}

.innerCommonWrap .innerPtext {
  font-size: 15px;
}

.mt0 {
  margin-top: 0;
}

.cart-icon {
  position: relative;
}

.ant-badge-count {
  position: absolute;
  top: 5px !important;
  right: -5px !important;
}

.customRadioGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.radioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 47%;
  height: 120px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}

.radioButton:hover {
  border-color: #1890ff;
}

.radioContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radioContent svg {
  margin-bottom: 10px;
}

.radioContent span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.headercartItemContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 10px;
  background: #fff;
  width: 100%;
}

.headercartItemDetails {
  margin-bottom: 10px;
}

.headercartItemName {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 5px;
}

.headercartItemSize, .headercartItemExtras {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 3px;
}

.headercartItemSize span, .headercartItemExtras span {
  display: inline-block;
  margin-right: 5px;
}

.headercartInputQtyWrap {
  justify-content: space-between;
  align-items: center;
}

.headercartQuantityControls {
  display: flex;
  align-items: center;
}

.headercartInputQty {
  width: 30px;
  text-align: center;
  font-size: 1rem;
  margin: 0 10px;
}

.headercartMinusBtn, .headercartPlusBtn {
  width: 30px; /* Increased button size for better visibility */
  height: 30px;
  font-size: 1.5rem; /* Increased font size for the symbols */
  font-weight: bold; /* Makes the symbols bold */
  border-radius: 5px;
  border: none; /* Removes border by default */
  color: white; /* Text color */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  display: flex; /* Centers the content */
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor for better UX */
}

/* Minus Button Styles */
.headercartMinusBtn {
  background-color: #ff4d4f; /* Light red */
}

.headercartMinusBtn:hover {
  background-color: #d9363e !important; /* Darker red on hover */
  border: 1px solid #ff7875 !important; /* Adds a light red border */
}

/* Plus Button Styles */
.headercartPlusBtn {
  background-color: #4caf50; /* Green */
}

.headercartPlusBtn:hover {
  background-color: #388e3c !important; /* Darker green on hover */
  border: 1px solid #66bb6a !important; /* Adds a light green border */
}


.headercartItemPricedetails {
  font-weight: bold;
  font-size: 1rem;
}


.galleryWrap {
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 200px;
}

.galleryWrap img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.5s;
}

.galleryWrap:hover img {
  transform: scale(1.5);
}

.menuBanner {
  position: relative;
  text-align: center;
}

.innerBannerTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
}

.menuBanner h2 {
  font-family:  -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
}

.headerCartOrderType .ant-radio-button-wrapper {
  padding-inline: 11px;
}

.headerCartOrderType {
  padding: 20px 0 25px;
}

.forgot-password {
  text-align: center;
  margin-top: 10px;
}

.forgot-password .link {
  color: #1890ff;
  cursor: pointer;
}

.social-login {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 16px;
  padding: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}

.twitter-btn {
  background-color: #1da1f2;
  color: white;
}

.google-btn {
  background-color: #db4437;
  color: white;
}

.facebook-btn {
  background-color: #3b5998;
  color: white;
}

.social-btn:hover
{
  border: 1px solid #ccc;
}

.social-btn svg {
  width: 20px;
  height: 20px;
}

/* Show the toggle button on mobile */
@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .header-container {
    flex-direction: column;
    /* Stack items vertically on mobile */
    align-items: flex-start;
    /* Align items to start */
  }

  .menu {
    flex-direction: column;
    /* Align menu items vertically */
    width: 100%;
    /* Ensure it takes full width */
    margin-top: 10px;
    /* Space between logo and menu */
  }

  .menu.mobile-menu {
    display: block;
    /* Show menu when mobile menu is active */
  }

  .menu .ant-menu-item {
    padding: 10px 20px;
    /* Increase padding for mobile items */
    font-weight: bold;
    /* Bold menu items */
  }

  .cart-item {
    margin-top: 10px;
    /* Space cart icon on mobile */
  }
}