/* src/components/HowItWorks.css */
.custom_container{padding: 0 50px;}
.howItWorkSection {
    text-align: center;
    padding: 3rem 0;
  }

  .howItWorkSection .customHeading{margin-top: 0; margin-bottom: 35px; font-weight: 900;
    color: rgb(45, 43, 40);
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;  
  }
  

 .steps p{font-size: 15px;}

 .steps h4{margin-top: 10px; }
  
  .step img {
    width: 100px;
    height: 100px;
  }
  