.foodCategoriesSection {
  padding: 1.5rem 2rem;
  background: #f4f4f7;
  /* background: #eef1fb;} */
}

.foodCategoriesSection .customHeading {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
  /* font-family: "WoltHeading-Omnes", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif; */
}
.food-items-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.customize-modal {
  width: 400px;
  /* Set a suitable width */
}

.discountPrice {
  text-decoration: line-through;
  color: #d10606;
  font-size: 15px;
}

.size-option,
.topping-option {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background: #f9f9f9;
  padding: 8px;
  border-radius: 5px;
  font-weight: 500;
}

.size-option label,
.topping-option label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.size-option .price,
.topping-option .price {
  margin-left: 10px;
  font-weight: bold;
  color: #3b3d2f;
  /* Price color */
}

.size-option input[type="radio"],
.topping-option input[type="checkbox"] {
  margin-right: 10px;
}

/* Optional: Additional styles for modal titles */


.categoryName {
  margin-top: 0px;
  margin-bottom: 0 !important;
  display: flex;
  font-size: 18px;
  align-items: center;
}

.categoryName svg {
  margin-right: 5px;
}

.categoryWrap .ant-card-body {
  padding: 0;
}

.categoryInfoWrap {
  padding: 11px 13px 0;
}

.categoryDiscription {
  margin-top: 7px;
  font-size: 16px;
  margin-bottom: 10px;
  color: #858585;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 45px;
  line-height: 22px;
}

.categoryRating {
  display: flex;
  align-items: center;
  background: rgb(221, 243, 221);
  padding: 1px 8px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(60, 181, 0);
  max-width: 40px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.categoryRating svg {
  margin-right: 4px;
}

.categoryPriceInfo {
  border-top: 1px solid #f2f2f2;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 11px;
  padding-bottom: 12px;
}

.categoryPrice {
  font-weight: 500;
  font-size: 19px;
  margin: 0;
}

.cartBtn {
  padding: 16px 11px;
  border: 1px #009de0 solid;
  color: #fff;
  background: #009de0;
  border-radius: 4px;
}

.cartBtn:hover {
  background: #009de0 !important;
  color: #fff !important;
}

.categoryImgWrap {
  height: 192px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}



.categoryImgWrap img {
  width: 100%;
  transition: transform .5s ease;
  cursor: pointer;
}

.categoryImgWrap:hover img {
  transform: scale(1.5);
}

.categoryNameModal {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
/*   font-family: "WoltHeading-Omnes", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Arimo", "Open Sans", sans-serif;
 */  margin-top: 0;
}


.categoryImgModal {
  height: 286px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.categoryImgModal img {
  width: 100%;
  height: 100%;
  transition: transform .5s ease;
  cursor: pointer;
}

.categoryImgModal:hover img {
  transform: scale(1.5);
}

.customize-modal .ant-modal-content {
  padding: 0 !important;
}

.customize-modal .ant-modal-close-x {
  background: #ededee;
  height: 33px;
  width: 33px;
  border-radius: 50%;
}

.categoryModalBody {
  padding: 10px 16px 0;
}



.categoryModalBody .categoryPriceModal {
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 0;
}

.categoryModalBody .categoryDiscriptionModal {
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-top: 7px;
  font-size: 16px;
  margin-bottom: 10px;
  color: #858585;
}

.customHr {
  border: .1px #e4e4e5 solid;
}

.categoryModalSizeWrap {
  padding: 0px 16px;
  height: 200px;
  overflow: overlay;
}

.sizeLabel .ant-checkbox .ant-checkbox-inner {
  border: 2px #e4e4e5 solid;
  width: 24px;
  height: 24px;
}

.sizeLabel .ant-radio .ant-radio-inner {
  border: 2px #e4e4e5 solid;
  width: 24px;
  height: 24px;
}

.ant-modal-footer {
  padding: 1rem !important;
  position: relative;
  margin-top: 0 !important;
  box-shadow: 0 0 10px #e5e5e5;
}

.categoryModalSizeWrap .size-option .sizeLabel label+.sizeCheckBox {
  margin-left: 13px;
}

.categoryModalSizeWrap .toppingHeading {
  margin-bottom: 6px;
  color: #000;
  font-weight: 700;
}

.categoryModalSizeWrap .toppingSubtext {
  margin-top: 0;
  margin-bottom: 20px;
}


/* Inner Menu Page CSS */

.foodItemsSection .foodItemMenuBox .ant-col {
  margin-bottom: 20px;
}

.categoryDescription {
  min-height: 5px;
}

.FoodInnerMenuWrap {
  background: #fbfbfb;
}

.foodItemsSection .categoryWrap {
  box-shadow: 0px 1px 3px #0000001f, 0px 0px 1px 0px #0000001f;
}

.foodItemOfferSection {
  margin-top: 30px;
}

.foodItemOfferBox {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 12px;
  background: radial-gradient(circle at left center, transparent 10px, rgba(0, 157, 224, 0.075) 0px, rgba(0, 157, 224, 0.075), transparent), radial-gradient(circle at right center, transparent 10px, rgba(0, 157, 224, 0.075) 0px, rgba(0, 157, 224, 0.075), transparent);
}

.dealImg img {
  width: 50px;
  height: 50px;
}

.categoryTitle {
  margin-bottom: 25px !important;
}

.menuCateRight a {
  font-weight: 500;
}


.PriceDescription {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 6px;
}

.platformPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin: 0;
}

.platformPrice .platformName {
  font-weight:500;
}

.platformPrice .price {
  font-weight: 700;
}

/* UberEats styling */
.uberEats .platformName {
  color: #06c167;
  /* UberEats green */
}

/* Deliveroo styling */
.deliveroo .platformName {
  color: #00cdbc;
  /* Deliveroo teal */
}

.discountPricemen{color: #eb1010;    font-size: 15px;}